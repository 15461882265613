.project {

    .section-title {
        margin-bottom: 3em;
    }

    &__item {
        .img-wrap {
            &.logo {
                margin: 0 auto;
                margin-bottom: 3em;
                max-width: 15em;
                > img {
                    max-width: 100%;
                }
            }
        }
        .content {
            p {
                margin-bottom: 1.5em;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .more-link {
            margin: 2em 0 4em;
        }
    }

    .projects {
        &__slider {
            .img-wrap {
                margin-bottom: 0;
                @media screen and (max-width: 991px) {
                    margin-top: 0;
                    margin-bottom: 1.5em;
                }
                > img {
                    position: static;
                    @include transform(none);
                    width: 100%;
                    height: auto;
                }
            }
        }
        &__slide-item {
            @media screen and (max-width: 991px) {
                padding-top: 0;
            }
        }
    }

}