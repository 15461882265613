.management-page {
    .management {

        &__item {
            margin-bottom: 120px;
            .info-content {
                padding-top: 1em;
                text-align: left;
                @media screen and (max-width: 768px) {
                    text-align: center;
                }
            }
            .name {
                margin-bottom: 1.25em;
            }
            .position {
                margin-bottom: 2em;
                font-weight: 500;
            }
            .descr-gen {
                max-width: none;
            }
        }

    }
}