.projects {
    @media screen and (max-width: 768px) {
        background-image: url("../../img/map-slider.png");
        background-repeat: no-repeat;
        background-size: auto;
        background-position: right bottom;
    }

    &__slider-wrap {
        width: 100%;
        max-width: 1240px;
        margin: 0 auto;
        margin-bottom: 3em;
        padding-bottom: 3em;
        white-space: nowrap;
        @media screen and (max-width: 991px) {
            width: auto;
            margin-left: -15px;
            margin-right: -15px;
            padding-bottom: 0;
        }
    }

    &__slider {
        position: relative;
        .slick-slide {
            &:focus {
                border: none;
                outline: none;
            }
        }
        .slick-arrow {
            position: absolute;
            top: 50%;
            width: 37px;
            height: 133px;
            @include transform(translateY(-50%));
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            background-color: transparent;
            border: none;
            outline: none;
            font-size: 0;
            cursor: pointer;
            @media screen and (max-width: 991px) {
                display: none !important;
            }
            &.slick-prev {
                left: -80px;
                background-image: url("../../img/arrow-slider-left.png");
                @media screen and (max-width: 1440px) {
                    left: -45px;
                }
                @media screen and (max-width: 1140px) {
                    left: -40px;
                }
            }
            &.slick-next {
                right: -80px;
                background-image: url("../../img/arrow-slider-right.png");
                @media screen and (max-width: 1440px) {
                    right: -45px;
                }
                @media screen and (max-width: 1140px) {
                    right: -40px;
                }
            }
        }
        .slick-dots {
            position: absolute;
            left: 50%;
            bottom: -3em;
            @include transform(translateX(-50%));
            @media screen and (max-width: 991px) {
                bottom: -.5em;
            }
            > li {
                display: inline-block;
                vertical-align: top;
                padding: 0 5px;
                > button {
                    width: 8px;
                    height: 8px;
                    padding: 0;
                    margin: 0;
                    border: none;
                    outline: none;
                    @include border-radius(50%);
                    background-color: #c9c9c9;
                    font-size: 0;
                    cursor: pointer;
                }
                &.slick-active {
                    > button {
                        background-color: $color-text-base;
                    }
                }
            }
        }
    }

    &__slide-item {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        @media screen and (max-width: 991px) {
            padding-top: 4em;
            position: relative;
            background-size: 0;
        }
        > .row {
            min-height: 30em;
            @media screen and (max-width: 991px) {
                min-height: auto;
                margin-right: 0;
                margin-left: 0;
            }
            .col-md-6 {
                @media screen and (max-width: 991px) {
                    position: static;
                    &:first-child {
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
            }
        }
        .img-wrap {
            position: relative;
            width: 100%;
            height: 100%;
            text-align: center;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 0;
            @media screen and (max-width: 991px) {
                margin-top: 1.5em;
                margin-bottom: 1.5em;
                background-size: cover;
                height: auto;
            }
            > img {
                position: absolute;
                left: 50%;
                top: 50%;
                @include transform(translate(-50%,-50%));
                max-width: 100%;
                max-height: 100%;
                height: auto;
                @media screen and (max-width: 991px) {
                    position: static;
                    @include transform(none);
                    max-height: none;
                    display: inline-block;
                    max-width: 90%;
                }
            }
        }
        .slide-text-content {
            white-space: normal;
            @media screen and (max-width: 991px) {
                text-align: center;
            }
        }
        .logo-wrap {
            width: 100%;
            max-width: 15em;
            margin-bottom: 3.5em;
            @media screen and (max-width: 991px) {
                position: absolute;
                left: 50%;
                top: 0;
                @include transform(translateX(-50%));
                max-height: 4em;
                text-align: center;
            }
            > img {
                display: inline-block;
                max-width: 100%;
                max-height: 100%;
                height: auto;
            }
        }
        .descr {
            margin-bottom: 2.5em;
            padding-right: 15px;
            text-align: left;
            @media screen and (max-width: 991px) {
                text-align: center;
            }
            @media screen and (max-width: 768px) {
                padding-right: 0;
            }
        }
        .more-link {
            margin-bottom: 2.5em;
        }
    }

}