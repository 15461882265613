/*===========================================
 Global Style
===========================================*/

html, body {
	width: 100%;
	min-height: 100%;
	margin: 0;
	padding: 0;
	font-family: GothamPro, Arial, Helvetica, Verdana, sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 1.2;
	color: $color-text-base;
	background-size: 100% auto;
}

body {
	position: relative;
	&.no-scroll {
		overflow: hidden;
	}
}

ul, ol {
	list-style: none;
}

a {
	@include transition(all, .2s, ease-in-out);
}

b {
	font-weight: 700;
}

/* text position */
.text-left{text-align: left!important}
.text-center{text-align: center!important}
.text-right{text-align: right!important}
.nowrap{white-space: nowrap!important;}

.text-upper {
	text-transform: uppercase;
}
.text-semibold {
	font-weight: 600;
}
.text-bold {
	font-weight: 700;
}
.text-thin {
	font-weight: 300;
}
.email-color {
	color: $email-color;
}

.clearfix::after {
	content: '';
	display: block;
	clear: both;
}

.container-fluid {
	width: auto;
	margin: 0 4%;
	padding: 0;
	@media screen and (max-width: 991px) {
		margin: 0 15px;
	}
}

.content {
	width: 100%;
	max-width: 1240px;
	margin: 0 auto;
}

.page-height {
	position: relative;
	min-height: 100vh;
}

section {
	padding-top: 3.75em;
	padding-bottom: 3.75em;
	.content {
		&.text-center {
			text-align: center;
		}
	}
	&.first-section {
		padding-top: 8em;
		.section-title {
			position: relative;
			padding-top: 1.5em;
			&::before {
				content: '';
				display: block;
				width: 100px;
				height: 1px;
				position: absolute;
				top: 0;
				left: 50%;
				@include transform(translateX(-50%));
				background-color: $color-text-base;
			}
		}
	}
}