.technologies {

    .skills-list {
        padding-top: 3em;
        padding-bottom: 2em;
        @media screen and (max-width: 991px) {
            padding-bottom: 0;
        }
    }

    .skill-item {
        padding: 0 1em;
        text-align: center;
        @media screen and (max-width: 991px) {
            margin-bottom: 3em;
        }
        .img-wrap {
            width: 100%;
            height: 5.6em;
            margin-bottom: 2.5em;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            @include transition(all, .2s, ease-in-out);
        }
        .title {
            margin-bottom: 1.5em;
            font-weight: 700;
        }
        .descr {
            color: $color-text-subtitle;
        }
        &.php {
            &:hover {
                .img-wrap {
                    background-image: url("../../img/TECHNOLOGIES_01-hover.png");
                }
            }
            .img-wrap {
                background-image: url("../../img/TECHNOLOGIES_01.png");
            }
        }
        &.windows {
            &:hover {
                .img-wrap {
                    background-image: url("../../img/TECHNOLOGIES_02-hover.png");
                }
            }
            .img-wrap {
                background-image: url("../../img/TECHNOLOGIES_02.png");
            }
        }
        &.asp {
            &:hover {
                .img-wrap {
                    background-image: url("../../img/TECHNOLOGIES_03-hover.png");
                }
            }
            .img-wrap {
                background-image: url("../../img/TECHNOLOGIES_03.png");
            }
        }
        &.databases {
            @media screen and (max-width: 991px) {
                margin-bottom: 0;
            }
            &:hover {
                .img-wrap {
                    background-image: url("../../img/TECHNOLOGIES_04-hover.png");
                }
            }
            .img-wrap {
                background-image: url("../../img/TECHNOLOGIES_04.png");
            }
        }
    }

}