.main-header {
    position: absolute;
    z-index: 100;
    width: 100%;
    top: 0;
    left: 0;
    background-color: $bg-header;
    padding: 10px 0;
    -webkit-box-shadow: 0px 3px 2px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 3px 2px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 3px 2px 0px rgba(0,0,0,0.15);
    @media screen and (max-width: 580px) {
        font-size: 0.875em;
    }

    .container-fluid {
        position: relative;
    }

    .main-logo {
        width: 7.8125em;
        margin-left: 15px;
        @media screen and (max-width: 991px) {
            position: relative;
            z-index: 10;
        }
        @media screen and (max-width: 580px) {
            width: 6.5em;
        }
        > a {
            display: inline-block;
            vertical-align: top;
            background-image: url("../../img/logo.png");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: left center;
            @include transition(all, .2s, ease-in-out);
            &:hover {
                background-image: url("../../img/logo-hover.png");
            }
            > img {
                width: 100%;
                height: auto;
                opacity: 0;
            }
        }
    }

    .main-menu {
        @include flex;
        align-items: center;
        .main-nav {
            margin-right: 1em;
        }
        .btn-wrap {
            @media screen and (max-width: 1200px) {
                position: absolute;
                z-index: 10;
                right: 8.5625em;
            }
            @media screen and (max-width: 580px) {
                right: 6.5714em;
            }
            .btn {
                margin-bottom: 0;
            }
        }
    }

    .main-nav {
        @media screen and (max-width: 1200px) {
            display: none;
            position: fixed;
            z-index: 9;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            padding-top: 20vh;
            background-color: $white;
            text-align: center;
        }
        > a {
            display: inline-block;
            vertical-align: top;
            padding: .6em 1em;
            font-weight: 600;
            color: inherit;
            text-transform: uppercase;
            text-decoration: none;
            @media screen and (max-width: 1280px) {
                padding: .6em 10px;
            }
            @media screen and (max-width: 1200px) {
                width: 100%;
                padding: 1vh 1em;
                margin-bottom: 4vh;
                font-size: 1.25em;
            }
            &:hover {
                color: $main-yellow;
            }
        }
    }

    .mob-btn {
        display: none;
        cursor: pointer;
        @media screen and (max-width: 1200px) {
            display: block;
            position: relative;
            z-index: 10;
        }
        &:hover {
            .btn {
                border-color: transparent;
                background-color: $main-yellow;
            }
            .btn-text {
                color: $main-yellow;
            }
        }
        &.open {
            .btn {
                border-color: transparent;
                background-color: $main-yellow;
                > span {
                    background-color: transparent;
                    &::before {
                        top: 0;
                        transform: rotate(45deg);
                    }
                    &::after {
                        bottom: 0;
                        transform: rotate(-45deg);
                    }
                }
            }
            .btn-text {
                color: $main-yellow;
            }
        }
        .btn {
            vertical-align: middle;
            margin-bottom: 0;
            margin-right: .5em;
            @include transition(all, .3s, ease-in-out);
            @media screen and (max-width: 420px) {
                margin-right: 0;
            }
            > span {
                display: inline-block;
                vertical-align: middle;
                position: relative;
                width: 60%;
                height: 2px;
                margin-top: -2px;
                background-color: $color-text-base;
                @include transition(all, .3s, ease-in-out);
                &::after,
                &::before {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 2px;
                    position: absolute;
                    left: 0;
                    background-color: $color-text-base;
                    @include transition(all, .3s, ease-in-out);
                }
                &::before {
                    top: -6px;
                }
                &::after {
                    bottom: -6px;
                }
            }
        }
        .btn-text {
            display: inline-block;
            vertical-align: middle;
            font-weight: 600;
            text-transform: uppercase;
            @include transition(all, .3s, ease-in-out);
            @media screen and (max-width: 420px) {
                display: none;
            }
        }
    }

    .main-contacts {
        margin-right: 15px;
        @media screen and (max-width: 991px) {
            position: relative;
            z-index: 10;
            padding-left: 4.375em;
        }
        @media screen and (max-width: 580px) {
            padding-left: 3.2857em;
        }
        .btn-wrap {
            .btn {
                margin-right: 1.5em;
                margin-bottom: 0;
                @media screen and (max-width: 580px) {
                    margin-right: .5em;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

}