.form-group {
    position: relative;
    width: 100%;
    margin-bottom: 1em;

    &.no-margin {
        margin-bottom: 0;
    }

    input[type="text"] {
        width: 100%;
        padding: .88889em 1em;
        border: 1px solid $input-border;
        outline: none;
        font-size: 1em;
        @include transition(all, .2s, ease-in-out);
        @include input-placeholder {
            color: $input-border;
            @include transition(all, .2s, ease-in-out);
        }
        &:hover,
        &:focus {
            border-color: $color-text-base;
            @include input-placeholder {
                color: $color-text-base;
            }
        }
    }

}

.btn-wrap {

    input[type="submit"] {
        &:focus, &:active {
            border: none;
            outline: none;
        }
    }

}