.full-height {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100vh;
    background-image: url("../../img/bg_lion.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    @media screen and (max-width: 991px) {
        background-position: 15% 0;
    }
    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,.4);
    }
    &::after {
        content: '';
        display: block;
        width: 100%;
        height: 50%;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: pink;
        @include gradient(transparent, rgba(0,0,0,.8))
    }
    .first-screen-content {
        position: absolute;
        z-index: 10;
        width: 100%;
        bottom: 0;
        color: $white;
        text-align: center;
        .subtitle {
            width: 100%;
            max-width: 1080px;
            margin: 0 auto;
            margin-bottom: 5vh;
            font-family: PFAgoraSlabPro, GothamPro, Arial, Helvetica, Verdana, sans-serif;
            font-size: 1.875em;
            font-style: italic;
            @media screen and (max-width: 991px) {
                font-size: 1.5em;
            }
            @media screen and (max-width: 580px) {
                font-size: 1em;
            }
        }
        .title {
            width: 100%;
            max-width: 1240px;
            margin: 0 auto;
            margin-bottom: 6vh;
            font-size: 4.5em;
            font-weight: 700;
            line-height: 1;
            text-transform: uppercase;
            @media screen and (max-width: 991px) {
                font-size: 2.5em;
            }
            @media screen and (max-width: 580px) {
                font-size: 2em;
            }
        }
        .btn {
            margin-bottom: 10vh;
            @media screen and (max-width: 580px) {
                margin-bottom: 7vh;
            }
        }
    }
}