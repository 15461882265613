.btn-wrap {
    position: relative;
}

.btn {
    display: inline-block;
    vertical-align: top;
    margin-right: 1em;
    margin-bottom: .5em;
    padding: .907em 2em;
    font-weight: 600;
    color: $color-text-base;
    text-align: center;
    @include transition(all, .2s, ease-in-out);
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: none;
    &:last-child {
        margin-right: 0;
    }
    &__circle {
        position: relative;
        width: 2.5em;
        height: 2.5em;
        line-height: 2.4em;
        padding: 0;
        border: 1px solid $color-text-base;
        @include border-radius(50%);
        &:hover {
            border-color: transparent;
            background-color: $main-yellow;
        }
        > svg {
            position: absolute;
            left: 50%;
            top: 50%;
            @include transform(translate(-50%,-50%));
        }
    }
    &__rounded-border {
        border: 1px solid $color-text-base;
        @include border-radius(2em);
    }
    &__white {
        border-color: $white;
        color: $white;
        &:hover {
            font-weight: 700;
            -webkit-box-shadow: 0px 0px 0px 1px rgba(255,255,255,1);
            -moz-box-shadow: 0px 0px 0px 1px rgba(255,255,255,1);
            box-shadow: 0px 0px 0px 1px rgba(255,255,255,1);
        }
    }
    &__yellow {
        border: none;
        background-color: $bg-btn-yellow;
        &:hover {
            background-color: $bg-btn-yellow-hover;
        }
    }
    &__black {
        border-color: $color-text-base;
        color: $color-text-base;
        &:hover {
            font-weight: 700;
            -webkit-box-shadow: 0px 0px 0px 1px $color-text-base;
            -moz-box-shadow: 0px 0px 0px 1px $color-text-base;
            box-shadow: 0px 0px 0px 1px $color-text-base;
        }
    }
}

.more-link {
    color: $email-color-hover;
    > a {
        color: $email-color-hover;
        &:hover {
            color: $email-color;
        }
    }
}