.contacts {
    padding-bottom: 1.5em;

    &__header {
        padding-bottom: 2em;
        .contacts-list {
            width: 100%;
            max-width: 700px;
            margin: 0 auto;
        }
        .contact-icon {
            @media screen and (max-width: 768px) {
                text-align: center;
            }
            a {
                display: inline-block;
                vertical-align: top;
                color: inherit;
                text-decoration: none;
                @media screen and (max-width: 768px) {
                    width: 270px;
                    text-align: left;
                    margin-bottom: .5em;
                }
            }
            &:hover {
                .contact-text {
                    color: $email-color-hover;
                }
            }
            .btn {
                margin-bottom: 0;
                border-color: transparent;
                background-color: $main-yellow;
                vertical-align: middle;
            }
            .contact-text {
                font-size: 1.5em;
                font-weight: 600;
                @include transition(all, .2s, ease-in-out);
                vertical-align: middle;
            }
            &.right {
                position: relative;
                padding-right: 5%;
                text-align: right;
                @media screen and (max-width: 768px) {
                    padding-right: 0;
                    text-align: center;
                }
                &::before {
                    content: '';
                    display: block;
                    width: 1px;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: -15px;
                    background-color: $color-text-subtitle;
                    @media screen and (max-width: 768px) {
                        content: none;
                    }
                }
            }
        }
    }

    &__body {
        padding-top: 2em;
        background-image: url("../../img/map.png");
        background-repeat: no-repeat;
        background-size: auto 100%;
        background-position: center center;
        @media screen and (max-width: 768px) {
            background-image: none;
        }
        .section-title {
            margin-bottom: 4em;
        }
        .contacts-col {
            margin-bottom: 5em;
            line-height: 1.5;
            &.first {
                @media screen and (max-width: 768px) {
                    position: relative;
                    &::after {
                        content: '';
                        display: block;
                        width: 100px;
                        height: 1px;
                        position: absolute;
                        bottom: -2.5em;
                        left: 50%;
                        @include transform(translateX(-50%));
                        background-color: $color-text-base;
                    }
                }
            }
            * {
                vertical-align: middle;
            }
            p > svg {
                font-size: .8em;
            }
            .phone {
                color: inherit;
                text-decoration: none;
                > svg {
                    font-size: .8em;
                }
            }
            .mail {
                color: $email-color;
                text-decoration: none;
                &:hover {
                    color: $email-color-hover;
                }
                > svg {
                    margin-top: .25em;
                    font-size: .8em;
                }
            }
        }
        .btn-wrap {
            margin-bottom: 4em;
        }
    }

    &__footer {
        padding-bottom: 2em;
        .content {
            p {
                @media screen and (max-width: 768px) {
                    margin-bottom: 1em;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            p > span {
                display: inline-block;
                vertical-align: top;
            }
            .point {
                @media screen and (max-width: 768px) {
                    display: none;
                }
            }
        }
    }

}