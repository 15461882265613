.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    fill: currentColor;
}

.icon-call-answer {
    font-size:(348.077/10)*1rem;
    width:(348.077/348.077)*1em;
}
.icon-email {
    font-size:(550.795/10)*1rem;
    width:(550.795/550.795)*1em;
}
.icon-linkedin {
    font-size:(430.117/10)*1rem;
    width:(430.117/430.117)*1em;
}
.icon-skype {
    font-size:(329.98/10)*1rem;
    width:(329.98/329.98)*1em;
}
