.section-title {
    margin-bottom: 2em;
    h3, .title {
        margin-bottom: .4em;
        font-size: 3em;
        font-weight: 700;
        line-height: 1;
        text-transform: uppercase;
        @media screen and (max-width: 768px) {
            font-size: 2.25em;
        }
    }
    .subtitle {
        font-family: PFAgoraSlabPro, GothamPro, Arial, Helvetica, Verdana, sans-serif;
        font-size: 1em;
        font-style: italic;
        color: $color-text-subtitle;
    }
}