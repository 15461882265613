@import url(../../node_modules/bootstrap/dist/css/bootstrap-grid.css);
@import url(../../node_modules/slick-carousel/slick/slick.css);
/*===========================================
 Fonts GothamPro
===========================================*/
@font-face {
  font-family: "GothamPro";
  src: url("../../fonts/GothamPro/GothamPro.eot");
  src: url("../../fonts/GothamPro/GothamPro.eot?#iefix") format("embedded-opentype"), url("../../fonts/GothamPro/GothamPro.woff") format("woff"), url("../../fonts/GothamPro/GothamPro.ttf") format("truetype"), url("../../fonts/GothamPro/GothamPro.svg#svgFontName") format("svg");
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: "GothamPro";
  src: url("../../fonts/GothamPro-Medium/GothamPro-Medium.eot");
  src: url("../../fonts/GothamPro-Medium/GothamPro-Medium.eot?#iefix") format("embedded-opentype"), url("../../fonts/GothamPro-Medium/GothamPro-Medium.woff") format("woff"), url("../../fonts/GothamPro-Medium/GothamPro-Medium.ttf") format("truetype"), url("../../fonts/GothamPro-Medium/GothamPro-Medium.svg#svgFontName") format("svg");
  font-style: normal;
  font-weight: 600; }

@font-face {
  font-family: "GothamPro";
  src: url("../../fonts/GothamPro-Bold/GothamPro-Bold.eot");
  src: url("../../fonts/GothamPro-Bold/GothamPro-Bold.eot?#iefix") format("embedded-opentype"), url("../../fonts/GothamPro-Bold/GothamPro-Bold.woff") format("woff"), url("../../fonts/GothamPro-Bold/GothamPro-Bold.ttf") format("truetype"), url("../../fonts/GothamPro-Bold/GothamPro-Bold.svg#svgFontName") format("svg");
  font-style: normal;
  font-weight: 700; }

/*===========================================
 Fonts PFAgoraSlabPro
===========================================*/
@font-face {
  font-family: "PFAgoraSlabPro";
  src: url("../../fonts/PFAgoraSlabPro-Italic/PFAgoraSlabPro-Italic.eot");
  src: url("../../fonts/PFAgoraSlabPro-Italic/PFAgoraSlabPro-Italic.eot?#iefix") format("embedded-opentype"), url("../../fonts/PFAgoraSlabPro-Italic/PFAgoraSlabPro-Italic.woff") format("woff"), url("../../fonts/PFAgoraSlabPro-Italic/PFAgoraSlabPro-Italic.ttf") format("truetype"), url("../../fonts/PFAgoraSlabPro-Italic/PFAgoraSlabPro-Italic.svg#svgFontName") format("svg");
  font-style: italic;
  font-weight: 400; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: '';
  content: none; }

q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/*===========================================
 Global Style
===========================================*/
html, body {
  width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: GothamPro, Arial, Helvetica, Verdana, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  color: #000000;
  background-size: 100% auto; }

body {
  position: relative; }
  body.no-scroll {
    overflow: hidden; }

ul, ol {
  list-style: none; }

a {
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out; }

b {
  font-weight: 700; }

/* text position */
.text-left {
  text-align: left !important; }

.text-center {
  text-align: center !important; }

.text-right {
  text-align: right !important; }

.nowrap {
  white-space: nowrap !important; }

.text-upper {
  text-transform: uppercase; }

.text-semibold {
  font-weight: 600; }

.text-bold {
  font-weight: 700; }

.text-thin {
  font-weight: 300; }

.email-color {
  color: #996633; }

.clearfix::after {
  content: '';
  display: block;
  clear: both; }

.container-fluid {
  width: auto;
  margin: 0 4%;
  padding: 0; }
  @media screen and (max-width: 991px) {
    .container-fluid {
      margin: 0 15px; } }

.content {
  width: 100%;
  max-width: 1240px;
  margin: 0 auto; }

.page-height {
  position: relative;
  min-height: 100vh; }

section {
  padding-top: 3.75em;
  padding-bottom: 3.75em; }
  section .content.text-center {
    text-align: center; }
  section.first-section {
    padding-top: 8em; }
    section.first-section .section-title {
      position: relative;
      padding-top: 1.5em; }
      section.first-section .section-title::before {
        content: '';
        display: block;
        width: 100px;
        height: 1px;
        position: absolute;
        top: 0;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
        background-color: #000000; }

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor; }

.icon-call-answer {
  font-size: 34.8077rem;
  width: 1em; }

.icon-email {
  font-size: 55.0795rem;
  width: 1em; }

.icon-linkedin {
  font-size: 43.0117rem;
  width: 1em; }

.icon-skype {
  font-size: 32.998rem;
  width: 1em; }

.btn-wrap {
  position: relative; }

.btn {
  display: inline-block;
  vertical-align: top;
  margin-right: 1em;
  margin-bottom: .5em;
  padding: .907em 2em;
  font-weight: 600;
  color: #000000;
  text-align: center;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none; }
  .btn:last-child {
    margin-right: 0; }
  .btn__circle {
    position: relative;
    width: 2.5em;
    height: 2.5em;
    line-height: 2.4em;
    padding: 0;
    border: 1px solid #000000;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%; }
    .btn__circle:hover {
      border-color: transparent;
      background-color: #ffcc00; }
    .btn__circle > svg {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
  .btn__rounded-border {
    border: 1px solid #000000;
    border-radius: 2em;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em; }
  .btn__white {
    border-color: #ffffff;
    color: #ffffff; }
    .btn__white:hover {
      font-weight: 700;
      -webkit-box-shadow: 0px 0px 0px 1px white;
      -moz-box-shadow: 0px 0px 0px 1px white;
      box-shadow: 0px 0px 0px 1px white; }
  .btn__yellow {
    border: none;
    background-color: #ffcc00; }
    .btn__yellow:hover {
      background-color: #efbf01; }
  .btn__black {
    border-color: #000000;
    color: #000000; }
    .btn__black:hover {
      font-weight: 700;
      -webkit-box-shadow: 0px 0px 0px 1px #000000;
      -moz-box-shadow: 0px 0px 0px 1px #000000;
      box-shadow: 0px 0px 0px 1px #000000; }

.more-link {
  color: #cc9900; }
  .more-link > a {
    color: #cc9900; }
    .more-link > a:hover {
      color: #996633; }

.main-header {
  position: absolute;
  z-index: 100;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #ffffff;
  padding: 10px 0;
  -webkit-box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.15); }
  @media screen and (max-width: 580px) {
    .main-header {
      font-size: 0.875em; } }
  .main-header .container-fluid {
    position: relative; }
  .main-header .main-logo {
    width: 7.8125em;
    margin-left: 15px; }
    @media screen and (max-width: 991px) {
      .main-header .main-logo {
        position: relative;
        z-index: 10; } }
    @media screen and (max-width: 580px) {
      .main-header .main-logo {
        width: 6.5em; } }
    .main-header .main-logo > a {
      display: inline-block;
      vertical-align: top;
      background-image: url("../../img/logo.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: left center;
      transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out; }
      .main-header .main-logo > a:hover {
        background-image: url("../../img/logo-hover.png"); }
      .main-header .main-logo > a > img {
        width: 100%;
        height: auto;
        opacity: 0; }
  .main-header .main-menu {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center; }
    .main-header .main-menu .main-nav {
      margin-right: 1em; }
    @media screen and (max-width: 1200px) {
      .main-header .main-menu .btn-wrap {
        position: absolute;
        z-index: 10;
        right: 8.5625em; } }
    @media screen and (max-width: 580px) {
      .main-header .main-menu .btn-wrap {
        right: 6.5714em; } }
    .main-header .main-menu .btn-wrap .btn {
      margin-bottom: 0; }
  @media screen and (max-width: 1200px) {
    .main-header .main-nav {
      display: none;
      position: fixed;
      z-index: 9;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      padding-top: 20vh;
      background-color: #ffffff;
      text-align: center; } }
  .main-header .main-nav > a {
    display: inline-block;
    vertical-align: top;
    padding: .6em 1em;
    font-weight: 600;
    color: inherit;
    text-transform: uppercase;
    text-decoration: none; }
    @media screen and (max-width: 1280px) {
      .main-header .main-nav > a {
        padding: .6em 10px; } }
    @media screen and (max-width: 1200px) {
      .main-header .main-nav > a {
        width: 100%;
        padding: 1vh 1em;
        margin-bottom: 4vh;
        font-size: 1.25em; } }
    .main-header .main-nav > a:hover {
      color: #ffcc00; }
  .main-header .mob-btn {
    display: none;
    cursor: pointer; }
    @media screen and (max-width: 1200px) {
      .main-header .mob-btn {
        display: block;
        position: relative;
        z-index: 10; } }
    .main-header .mob-btn:hover .btn {
      border-color: transparent;
      background-color: #ffcc00; }
    .main-header .mob-btn:hover .btn-text {
      color: #ffcc00; }
    .main-header .mob-btn.open .btn {
      border-color: transparent;
      background-color: #ffcc00; }
      .main-header .mob-btn.open .btn > span {
        background-color: transparent; }
        .main-header .mob-btn.open .btn > span::before {
          top: 0;
          transform: rotate(45deg); }
        .main-header .mob-btn.open .btn > span::after {
          bottom: 0;
          transform: rotate(-45deg); }
    .main-header .mob-btn.open .btn-text {
      color: #ffcc00; }
    .main-header .mob-btn .btn {
      vertical-align: middle;
      margin-bottom: 0;
      margin-right: .5em;
      transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out; }
      @media screen and (max-width: 420px) {
        .main-header .mob-btn .btn {
          margin-right: 0; } }
      .main-header .mob-btn .btn > span {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        width: 60%;
        height: 2px;
        margin-top: -2px;
        background-color: #000000;
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out; }
        .main-header .mob-btn .btn > span::after, .main-header .mob-btn .btn > span::before {
          content: '';
          display: block;
          width: 100%;
          height: 2px;
          position: absolute;
          left: 0;
          background-color: #000000;
          transition: all 0.3s ease-in-out;
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out; }
        .main-header .mob-btn .btn > span::before {
          top: -6px; }
        .main-header .mob-btn .btn > span::after {
          bottom: -6px; }
    .main-header .mob-btn .btn-text {
      display: inline-block;
      vertical-align: middle;
      font-weight: 600;
      text-transform: uppercase;
      transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out; }
      @media screen and (max-width: 420px) {
        .main-header .mob-btn .btn-text {
          display: none; } }
  .main-header .main-contacts {
    margin-right: 15px; }
    @media screen and (max-width: 991px) {
      .main-header .main-contacts {
        position: relative;
        z-index: 10;
        padding-left: 4.375em; } }
    @media screen and (max-width: 580px) {
      .main-header .main-contacts {
        padding-left: 3.2857em; } }
    .main-header .main-contacts .btn-wrap .btn {
      margin-right: 1.5em;
      margin-bottom: 0; }
      @media screen and (max-width: 580px) {
        .main-header .main-contacts .btn-wrap .btn {
          margin-right: .5em; } }
      .main-header .main-contacts .btn-wrap .btn:last-child {
        margin-right: 0; }

.section-title {
  margin-bottom: 2em; }
  .section-title h3, .section-title .title {
    margin-bottom: .4em;
    font-size: 3em;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase; }
    @media screen and (max-width: 768px) {
      .section-title h3, .section-title .title {
        font-size: 2.25em; } }
  .section-title .subtitle {
    font-family: PFAgoraSlabPro, GothamPro, Arial, Helvetica, Verdana, sans-serif;
    font-size: 1em;
    font-style: italic;
    color: #999999; }

.popup {
  display: none; }
  .popup__bg {
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8); }
  .popup__wrap {
    position: absolute;
    width: 100%;
    max-width: 820px;
    height: auto;
    top: 50%;
    left: 50%;
    padding: 0 15px;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  .popup__center-block {
    position: relative;
    padding: 2.8125em 7.5em;
    background-color: #ffffff; }
    @media screen and (max-width: 768px) {
      .popup__center-block {
        padding: 2em 1em; } }
  .popup__close {
    position: absolute;
    width: 3em;
    height: 3em;
    margin-bottom: .5em;
    right: 0;
    bottom: 100%;
    border: 1px solid #000000;
    background-image: url("../../img/icon-close.png");
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out; }
    .popup__close:hover {
      background-color: #ffcc00;
      border: none; }
  .popup__title {
    margin-bottom: 4em;
    text-align: center; }
    @media screen and (max-width: 768px) {
      .popup__title {
        margin-bottom: 2em; } }
  .popup__content .form-group input[type="text"] {
    font-size: 1.125em; }
  .popup__content .btn-wrap {
    margin-top: 4em;
    margin-bottom: 2em;
    text-align: center; }
    @media screen and (max-width: 768px) {
      .popup__content .btn-wrap {
        margin-top: 2em;
        margin-bottom: 0; } }
    .popup__content .btn-wrap input {
      padding-top: 1em;
      font-size: 1em; }
    .popup__content .btn-wrap .btn {
      margin-bottom: 0; }

.form-group {
  position: relative;
  width: 100%;
  margin-bottom: 1em; }
  .form-group.no-margin {
    margin-bottom: 0; }
  .form-group input[type="text"] {
    width: 100%;
    padding: .88889em 1em;
    border: 1px solid #999999;
    outline: none;
    font-size: 1em;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out; }
    .form-group input[type="text"].placeholder {
      color: #999999;
      transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out; }
    .form-group input[type="text"]:-moz-placeholder {
      color: #999999;
      transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out; }
    .form-group input[type="text"]::-moz-placeholder {
      color: #999999;
      transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out; }
    .form-group input[type="text"]:-ms-input-placeholder {
      color: #999999;
      transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out; }
    .form-group input[type="text"]::-webkit-input-placeholder {
      color: #999999;
      transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out; }
    .form-group input[type="text"]:hover, .form-group input[type="text"]:focus {
      border-color: #000000; }
      .form-group input[type="text"]:hover.placeholder, .form-group input[type="text"]:focus.placeholder {
        color: #000000; }
      .form-group input[type="text"]:hover:-moz-placeholder, .form-group input[type="text"]:focus:-moz-placeholder {
        color: #000000; }
      .form-group input[type="text"]:hover::-moz-placeholder, .form-group input[type="text"]:focus::-moz-placeholder {
        color: #000000; }
      .form-group input[type="text"]:hover:-ms-input-placeholder, .form-group input[type="text"]:focus:-ms-input-placeholder {
        color: #000000; }
      .form-group input[type="text"]:hover::-webkit-input-placeholder, .form-group input[type="text"]:focus::-webkit-input-placeholder {
        color: #000000; }

.btn-wrap input[type="submit"]:focus, .btn-wrap input[type="submit"]:active {
  border: none;
  outline: none; }

.full-height {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100vh;
  background-image: url("../../img/bg_lion.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed; }
  @media screen and (max-width: 991px) {
    .full-height {
      background-position: 15% 0; } }
  .full-height::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4); }
  .full-height::after {
    content: '';
    display: block;
    width: 100%;
    height: 50%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: pink;
    background-color: transparent;
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=transparent, endColorstr=rgba(0, 0, 0, 0.8));
    background-image: -moz-linear-gradient(center top, transparent, rgba(0, 0, 0, 0.8));
    background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from(transparent), to(rgba(0, 0, 0, 0.8))); }
  .full-height .first-screen-content {
    position: absolute;
    z-index: 10;
    width: 100%;
    bottom: 0;
    color: #ffffff;
    text-align: center; }
    .full-height .first-screen-content .subtitle {
      width: 100%;
      max-width: 1080px;
      margin: 0 auto;
      margin-bottom: 5vh;
      font-family: PFAgoraSlabPro, GothamPro, Arial, Helvetica, Verdana, sans-serif;
      font-size: 1.875em;
      font-style: italic; }
      @media screen and (max-width: 991px) {
        .full-height .first-screen-content .subtitle {
          font-size: 1.5em; } }
      @media screen and (max-width: 580px) {
        .full-height .first-screen-content .subtitle {
          font-size: 1em; } }
    .full-height .first-screen-content .title {
      width: 100%;
      max-width: 1240px;
      margin: 0 auto;
      margin-bottom: 6vh;
      font-size: 4.5em;
      font-weight: 700;
      line-height: 1;
      text-transform: uppercase; }
      @media screen and (max-width: 991px) {
        .full-height .first-screen-content .title {
          font-size: 2.5em; } }
      @media screen and (max-width: 580px) {
        .full-height .first-screen-content .title {
          font-size: 2em; } }
    .full-height .first-screen-content .btn {
      margin-bottom: 10vh; }
      @media screen and (max-width: 580px) {
        .full-height .first-screen-content .btn {
          margin-bottom: 7vh; } }

.offer {
  position: relative; }
  .offer::after {
    content: '';
    display: block;
    width: 7em;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -3.5em;
    background-color: #000000; }
  .offer .btn-wrap {
    margin-top: 4em; }

.technologies .skills-list {
  padding-top: 3em;
  padding-bottom: 2em; }
  @media screen and (max-width: 991px) {
    .technologies .skills-list {
      padding-bottom: 0; } }

.technologies .skill-item {
  padding: 0 1em;
  text-align: center; }
  @media screen and (max-width: 991px) {
    .technologies .skill-item {
      margin-bottom: 3em; } }
  .technologies .skill-item .img-wrap {
    width: 100%;
    height: 5.6em;
    margin-bottom: 2.5em;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out; }
  .technologies .skill-item .title {
    margin-bottom: 1.5em;
    font-weight: 700; }
  .technologies .skill-item .descr {
    color: #999999; }
  .technologies .skill-item.php:hover .img-wrap {
    background-image: url("../../img/TECHNOLOGIES_01-hover.png"); }
  .technologies .skill-item.php .img-wrap {
    background-image: url("../../img/TECHNOLOGIES_01.png"); }
  .technologies .skill-item.windows:hover .img-wrap {
    background-image: url("../../img/TECHNOLOGIES_02-hover.png"); }
  .technologies .skill-item.windows .img-wrap {
    background-image: url("../../img/TECHNOLOGIES_02.png"); }
  .technologies .skill-item.asp:hover .img-wrap {
    background-image: url("../../img/TECHNOLOGIES_03-hover.png"); }
  .technologies .skill-item.asp .img-wrap {
    background-image: url("../../img/TECHNOLOGIES_03.png"); }
  @media screen and (max-width: 991px) {
    .technologies .skill-item.databases {
      margin-bottom: 0; } }
  .technologies .skill-item.databases:hover .img-wrap {
    background-image: url("../../img/TECHNOLOGIES_04-hover.png"); }
  .technologies .skill-item.databases .img-wrap {
    background-image: url("../../img/TECHNOLOGIES_04.png"); }

.pricing {
  background-color: #f4f4f4; }
  .pricing .btn-wrap {
    margin-top: 4em; }

@media screen and (max-width: 991px) {
  .management {
    padding-bottom: 2em; } }

.management__list {
  padding-top: 3em; }

.management__item {
  text-align: center; }
  @media screen and (max-width: 991px) {
    .management__item {
      margin-bottom: 3em; } }
  .management__item .img-wrap {
    width: 100%;
    max-width: 190px;
    margin: 0 auto;
    margin-bottom: 30px;
    font-size: 0;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    overflow: hidden; }
    @media screen and (max-width: 768px) {
      .management__item .img-wrap {
        max-width: 160px; } }
    .management__item .img-wrap > img {
      width: 100%;
      height: auto; }
  .management__item .name {
    margin-bottom: 1.5em;
    font-weight: 700; }
  .management__item .position {
    margin-bottom: 1.5em;
    font-family: PFAgoraSlabPro, GothamPro, Arial, Helvetica, Verdana, sans-serif;
    font-style: italic;
    color: #666666; }
  .management__item .btn-wrap {
    margin-bottom: 1.5em; }
    .management__item .btn-wrap .btn {
      margin-right: .5em; }
  .management__item .descr-gen, .management__item .descr-sub {
    width: 100%;
    max-width: 430px;
    margin: 0 auto; }
  .management__item .descr-gen {
    margin-bottom: 1em; }
  .management__item .descr-sub {
    color: #666666; }

.management-page .management__item {
  margin-bottom: 120px; }
  .management-page .management__item .info-content {
    padding-top: 1em;
    text-align: left; }
    @media screen and (max-width: 768px) {
      .management-page .management__item .info-content {
        text-align: center; } }
  .management-page .management__item .name {
    margin-bottom: 1.25em; }
  .management-page .management__item .position {
    margin-bottom: 2em;
    font-weight: 500; }
  .management-page .management__item .descr-gen {
    max-width: none; }

.about {
  padding-bottom: 5em;
  background-color: #f4f4f4; }
  .about .section-title {
    margin-bottom: 3.5em; }

.contacts {
  padding-bottom: 1.5em; }
  .contacts__header {
    padding-bottom: 2em; }
    .contacts__header .contacts-list {
      width: 100%;
      max-width: 700px;
      margin: 0 auto; }
    @media screen and (max-width: 768px) {
      .contacts__header .contact-icon {
        text-align: center; } }
    .contacts__header .contact-icon a {
      display: inline-block;
      vertical-align: top;
      color: inherit;
      text-decoration: none; }
      @media screen and (max-width: 768px) {
        .contacts__header .contact-icon a {
          width: 270px;
          text-align: left;
          margin-bottom: .5em; } }
    .contacts__header .contact-icon:hover .contact-text {
      color: #cc9900; }
    .contacts__header .contact-icon .btn {
      margin-bottom: 0;
      border-color: transparent;
      background-color: #ffcc00;
      vertical-align: middle; }
    .contacts__header .contact-icon .contact-text {
      font-size: 1.5em;
      font-weight: 600;
      transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      vertical-align: middle; }
    .contacts__header .contact-icon.right {
      position: relative;
      padding-right: 5%;
      text-align: right; }
      @media screen and (max-width: 768px) {
        .contacts__header .contact-icon.right {
          padding-right: 0;
          text-align: center; } }
      .contacts__header .contact-icon.right::before {
        content: '';
        display: block;
        width: 1px;
        height: 100%;
        position: absolute;
        top: 0;
        left: -15px;
        background-color: #999999; }
        @media screen and (max-width: 768px) {
          .contacts__header .contact-icon.right::before {
            content: none; } }
  .contacts__body {
    padding-top: 2em;
    background-image: url("../../img/map.png");
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center center; }
    @media screen and (max-width: 768px) {
      .contacts__body {
        background-image: none; } }
    .contacts__body .section-title {
      margin-bottom: 4em; }
    .contacts__body .contacts-col {
      margin-bottom: 5em;
      line-height: 1.5; }
      @media screen and (max-width: 768px) {
        .contacts__body .contacts-col.first {
          position: relative; }
          .contacts__body .contacts-col.first::after {
            content: '';
            display: block;
            width: 100px;
            height: 1px;
            position: absolute;
            bottom: -2.5em;
            left: 50%;
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            -o-transform: translateX(-50%);
            transform: translateX(-50%);
            background-color: #000000; } }
      .contacts__body .contacts-col * {
        vertical-align: middle; }
      .contacts__body .contacts-col p > svg {
        font-size: .8em; }
      .contacts__body .contacts-col .phone {
        color: inherit;
        text-decoration: none; }
        .contacts__body .contacts-col .phone > svg {
          font-size: .8em; }
      .contacts__body .contacts-col .mail {
        color: #996633;
        text-decoration: none; }
        .contacts__body .contacts-col .mail:hover {
          color: #cc9900; }
        .contacts__body .contacts-col .mail > svg {
          margin-top: .25em;
          font-size: .8em; }
    .contacts__body .btn-wrap {
      margin-bottom: 4em; }
  .contacts__footer {
    padding-bottom: 2em; }
    @media screen and (max-width: 768px) {
      .contacts__footer .content p {
        margin-bottom: 1em; }
        .contacts__footer .content p:last-child {
          margin-bottom: 0; } }
    .contacts__footer .content p > span {
      display: inline-block;
      vertical-align: top; }
    @media screen and (max-width: 768px) {
      .contacts__footer .content .point {
        display: none; } }

@media screen and (max-width: 768px) {
  .projects {
    background-image: url("../../img/map-slider.png");
    background-repeat: no-repeat;
    background-size: auto;
    background-position: right bottom; } }

.projects__slider-wrap {
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  margin-bottom: 3em;
  padding-bottom: 3em;
  white-space: nowrap; }
  @media screen and (max-width: 991px) {
    .projects__slider-wrap {
      width: auto;
      margin-left: -15px;
      margin-right: -15px;
      padding-bottom: 0; } }

.projects__slider {
  position: relative; }
  .projects__slider .slick-slide:focus {
    border: none;
    outline: none; }
  .projects__slider .slick-arrow {
    position: absolute;
    top: 50%;
    width: 37px;
    height: 133px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 0;
    cursor: pointer; }
    @media screen and (max-width: 991px) {
      .projects__slider .slick-arrow {
        display: none !important; } }
    .projects__slider .slick-arrow.slick-prev {
      left: -80px;
      background-image: url("../../img/arrow-slider-left.png"); }
      @media screen and (max-width: 1440px) {
        .projects__slider .slick-arrow.slick-prev {
          left: -45px; } }
      @media screen and (max-width: 1140px) {
        .projects__slider .slick-arrow.slick-prev {
          left: -40px; } }
    .projects__slider .slick-arrow.slick-next {
      right: -80px;
      background-image: url("../../img/arrow-slider-right.png"); }
      @media screen and (max-width: 1440px) {
        .projects__slider .slick-arrow.slick-next {
          right: -45px; } }
      @media screen and (max-width: 1140px) {
        .projects__slider .slick-arrow.slick-next {
          right: -40px; } }
  .projects__slider .slick-dots {
    position: absolute;
    left: 50%;
    bottom: -3em;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%); }
    @media screen and (max-width: 991px) {
      .projects__slider .slick-dots {
        bottom: -.5em; } }
    .projects__slider .slick-dots > li {
      display: inline-block;
      vertical-align: top;
      padding: 0 5px; }
      .projects__slider .slick-dots > li > button {
        width: 8px;
        height: 8px;
        padding: 0;
        margin: 0;
        border: none;
        outline: none;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        background-color: #c9c9c9;
        font-size: 0;
        cursor: pointer; }
      .projects__slider .slick-dots > li.slick-active > button {
        background-color: #000000; }

.projects__slide-item {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }
  @media screen and (max-width: 991px) {
    .projects__slide-item {
      padding-top: 4em;
      position: relative;
      background-size: 0; } }
  .projects__slide-item > .row {
    min-height: 30em; }
    @media screen and (max-width: 991px) {
      .projects__slide-item > .row {
        min-height: auto;
        margin-right: 0;
        margin-left: 0; } }
    @media screen and (max-width: 991px) {
      .projects__slide-item > .row .col-md-6 {
        position: static; }
        .projects__slide-item > .row .col-md-6:first-child {
          padding-left: 0;
          padding-right: 0; } }
  .projects__slide-item .img-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 0; }
    @media screen and (max-width: 991px) {
      .projects__slide-item .img-wrap {
        margin-top: 1.5em;
        margin-bottom: 1.5em;
        background-size: cover;
        height: auto; } }
    .projects__slide-item .img-wrap > img {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      max-width: 100%;
      max-height: 100%;
      height: auto; }
      @media screen and (max-width: 991px) {
        .projects__slide-item .img-wrap > img {
          position: static;
          -webkit-transform: none;
          -moz-transform: none;
          -ms-transform: none;
          -o-transform: none;
          transform: none;
          max-height: none;
          display: inline-block;
          max-width: 90%; } }
  .projects__slide-item .slide-text-content {
    white-space: normal; }
    @media screen and (max-width: 991px) {
      .projects__slide-item .slide-text-content {
        text-align: center; } }
  .projects__slide-item .logo-wrap {
    width: 100%;
    max-width: 15em;
    margin-bottom: 3.5em; }
    @media screen and (max-width: 991px) {
      .projects__slide-item .logo-wrap {
        position: absolute;
        left: 50%;
        top: 0;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
        max-height: 4em;
        text-align: center; } }
    .projects__slide-item .logo-wrap > img {
      display: inline-block;
      max-width: 100%;
      max-height: 100%;
      height: auto; }
  .projects__slide-item .descr {
    margin-bottom: 2.5em;
    padding-right: 15px;
    text-align: left; }
    @media screen and (max-width: 991px) {
      .projects__slide-item .descr {
        text-align: center; } }
    @media screen and (max-width: 768px) {
      .projects__slide-item .descr {
        padding-right: 0; } }
  .projects__slide-item .more-link {
    margin-bottom: 2.5em; }

.projects-all__list {
  padding-top: 2em; }

.projects-all__item {
  margin-bottom: 6em; }
  .projects-all__item .logo-wrap {
    width: 100%;
    max-width: 15em;
    height: 3em;
    margin: 0 auto;
    margin-bottom: 1.5em;
    text-align: center; }
    .projects-all__item .logo-wrap > img {
      display: inline-block;
      max-width: 100%;
      max-height: 100%;
      height: auto; }
  .projects-all__item .img-wrap {
    position: relative;
    width: 100%;
    max-width: 230px;
    height: 160px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 20px; }
    .projects-all__item .img-wrap > img {
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 100%; }
  .projects-all__item .descr {
    margin-bottom: 1.5em;
    padding: 0 5%;
    text-align: center; }
    @media screen and (max-width: 991px) {
      .projects-all__item .descr {
        padding: 0; } }
  .projects-all__item .more-link {
    color: #cc9900;
    text-align: center; }
    .projects-all__item .more-link > a {
      color: #cc9900; }
      .projects-all__item .more-link > a:hover {
        color: #996633; }

.projects-all .btn-wrap {
  margin-bottom: 3em; }

@media screen and (max-width: 768px) {
  .projects-all__footer .content p {
    margin-bottom: 1em; }
    .projects-all__footer .content p:last-child {
      margin-bottom: 0; } }

.projects-all__footer .content p > span {
  display: inline-block;
  vertical-align: top; }

@media screen and (max-width: 768px) {
  .projects-all__footer .content .point {
    display: none; } }

.project .section-title {
  margin-bottom: 3em; }

.project__item .img-wrap.logo {
  margin: 0 auto;
  margin-bottom: 3em;
  max-width: 15em; }
  .project__item .img-wrap.logo > img {
    max-width: 100%; }

.project__item .content p {
  margin-bottom: 1.5em; }
  .project__item .content p:last-child {
    margin-bottom: 0; }

.project__item .more-link {
  margin: 2em 0 4em; }

.project .projects__slider .img-wrap {
  margin-bottom: 0; }
  @media screen and (max-width: 991px) {
    .project .projects__slider .img-wrap {
      margin-top: 0;
      margin-bottom: 1.5em; } }
  .project .projects__slider .img-wrap > img {
    position: static;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
    width: 100%;
    height: auto; }

@media screen and (max-width: 991px) {
  .project .projects__slide-item {
    padding-top: 0; } }

.main-footer {
  padding: 2em 0;
  background-color: #f4f4f4; }
  @media screen and (max-width: 768px) {
    .main-footer .content {
      font-size: .875em; } }
  .main-footer .content p {
    margin-bottom: .5em; }
    .main-footer .content p:last-child {
      margin-bottom: 0; }
  .main-footer .content p > span,
  .main-footer .content p > b {
    display: inline-block;
    vertical-align: top;
    padding-right: 1em; }
