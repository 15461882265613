.main-footer {
    padding: 2em 0;
    background-color: $bg-light-gray;
    .content {
        @media screen and (max-width: 768px) {
            font-size: .875em;
        }
        p {
            margin-bottom: .5em;
            &:last-child {
                margin-bottom: 0;
            }
        }
        p > span,
        p > b {
            display: inline-block;
            vertical-align: top;
            padding-right: 1em;
        }
    }
}