.offer {
    position: relative;

    &::after {
        content: '';
        display: block;
        width: 7em;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -3.5em;
        background-color: $color-text-base;
    }

    .btn-wrap {
        margin-top: 4em;
    }

}