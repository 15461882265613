//Cross browser CSS3 mixins

@mixin box-shadow($left, $top, $right, $bottom, $color) {
    box-shadow: $left $top $right $bottom $color;
    -webkit-box-shadow: $left $top $right $bottom $color;
    -moz-box-shadow: $left $top $right $bottom $color;
}

@mixin box-shadow-inset($left, $top, $right, $bottom, $color) {
    box-shadow: inset $left $top $right $bottom $color;
    -webkit-box-shadow: inset $left $top $right $bottom $color;
    -moz-box-shadow: inset $left $top $right $bottom $color;
}

@mixin transition($property, $duration, $easing: linear) {
    transition: $property $duration $easing;
    -webkit-transition: $property $duration $easing;
    -moz-transition: $property $duration $easing;
    -o-transition: $property $duration $easing;
}

@mixin border-radius($radius) {
    border-radius: $radius;
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
}

@mixin border-radii($topleft, $topright, $bottomright, $bottomleft) {
    border-top-left-radius: $topleft;
    border-top-right-radius: $topright;
    border-bottom-right-radius: $bottomright;
    border-bottom-left-radius: $bottomleft;
    -webkit-border-top-left-radius: $topleft;
    -webkit-border-top-right-radius: $topright;
    -webkit-border-bottom-right-radius: $bottomright;
    -webkit-border-bottom-left-radius: $bottomleft;
    -moz-border-radius-topleft: $topleft;
    -moz-border-radius-topright: $topright;
    -moz-border-radius-bottomright: $bottomright;
    -moz-border-radius-bottomleft: $bottomleft;
}

@mixin box-sizing(){
    box-sizing: border-box; /* css3 rec */
    -moz-box-sizing: border-box; /* ff2 */
    -ms-box-sizing: border-box; /* ie8 */
    -webkit-box-sizing: border-box; /* safari3 */
    -khtml-box-sizing: border-box; /* konqueror */
}

@mixin gradient($color1, $color2) {
    background-color: $color1;
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#{$color1}, endColorstr=#{$color2});
    background-image: -moz-linear-gradient(center top, $color1, $color2);
    background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from($color1), to($color2));
}

@mixin calc($property, $expression) {
    #{$property}: -moz-calc(#{$expression});
    #{$property}: -webkit-calc(#{$expression});
    #{$property}: calc(#{$expression});
}

@mixin input-placeholder {
    &.placeholder { @content; }
    &:-moz-placeholder { @content; }
    &::-moz-placeholder { @content; }
    &:-ms-input-placeholder { @content; }
    &::-webkit-input-placeholder { @content; }
}


@function em($pixels, $context: $baseFontSize) {
    @return #{$pixels/$context}em
}

@mixin transform($property) {
    -webkit-transform: #{$property};
    -moz-transform: #{$property};
    -ms-transform: #{$property};
    -o-transform: #{$property};
    transform: #{$property};
}

@mixin flex(){
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}