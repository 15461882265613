.popup {
    display: none;

    &__bg {
        position: fixed;
        z-index: 1000;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: $bg-popup;
    }

    &__wrap {
        position: absolute;
        width: 100%;
        max-width: 820px;
        height: auto;
        top: 50%;
        left: 50%;
        padding: 0 15px;
        @include transform(translate(-50%,-50%));
    }

    &__center-block {
        position: relative;
        padding: 2.8125em 7.5em;
        background-color: $white;
        @media screen and (max-width: 768px) {
            padding: 2em 1em;
        }
    }

    &__close {
        position: absolute;
        width: 3em;
        height: 3em;
        margin-bottom: .5em;
        right: 0;
        bottom: 100%;
        border: 1px solid $color-text-base;
        background-image: url("../../img/icon-close.png");
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center center;
        @include border-radius(50%);
        @include transition(all, .2s, ease-in-out);
        &:hover {
            background-color: $main-yellow;
            border: none;
        }
    }

    &__title {
        margin-bottom: 4em;
        text-align: center;
        @media screen and (max-width: 768px) {
            margin-bottom: 2em;
        }
    }

    &__content {
        .form-group {
            input[type="text"] {
                font-size: 1.125em;
            }
        }
        .btn-wrap {
            margin-top: 4em;
            margin-bottom: 2em;
            text-align: center;
            @media screen and (max-width: 768px) {
                margin-top: 2em;
                margin-bottom: 0;
            }
            input {
                padding-top: 1em;
                font-size: 1em;
            }
            .btn {
                margin-bottom: 0;
            }
        }
    }

}