/*===========================================
 Fonts GothamPro
===========================================*/

// Regular
@font-face {
	font-family: "GothamPro";
	src: url("../../fonts/GothamPro/GothamPro.eot");
	src: url("../../fonts/GothamPro/GothamPro.eot?#iefix") format("embedded-opentype"),
	url("../../fonts/GothamPro/GothamPro.woff") format("woff"),
	url("../../fonts/GothamPro/GothamPro.ttf") format("truetype"),
	url("../../fonts/GothamPro/GothamPro.svg#svgFontName") format('svg');
	font-style: normal;
	font-weight: 400;
}

// Medium
@font-face {
	font-family: "GothamPro";
	src: url("../../fonts/GothamPro-Medium/GothamPro-Medium.eot");
	src: url("../../fonts/GothamPro-Medium/GothamPro-Medium.eot?#iefix") format("embedded-opentype"),
	url("../../fonts/GothamPro-Medium/GothamPro-Medium.woff") format("woff"),
	url("../../fonts/GothamPro-Medium/GothamPro-Medium.ttf") format("truetype"),
	url("../../fonts/GothamPro-Medium/GothamPro-Medium.svg#svgFontName") format('svg');
	font-style: normal;
	font-weight: 600;
}

// Bold
@font-face {
	font-family: "GothamPro";
	src: url("../../fonts/GothamPro-Bold/GothamPro-Bold.eot");
	src: url("../../fonts/GothamPro-Bold/GothamPro-Bold.eot?#iefix") format("embedded-opentype"),
	url("../../fonts/GothamPro-Bold/GothamPro-Bold.woff") format("woff"),
	url("../../fonts/GothamPro-Bold/GothamPro-Bold.ttf") format("truetype"),
	url("../../fonts/GothamPro-Bold/GothamPro-Bold.svg#svgFontName") format('svg');
	font-style: normal;
	font-weight: 700;
}

/*===========================================
 Fonts PFAgoraSlabPro
===========================================*/

// Italic
@font-face {
	font-family: "PFAgoraSlabPro";
	src: url("../../fonts/PFAgoraSlabPro-Italic/PFAgoraSlabPro-Italic.eot");
	src: url("../../fonts/PFAgoraSlabPro-Italic/PFAgoraSlabPro-Italic.eot?#iefix") format("embedded-opentype"),
	url("../../fonts/PFAgoraSlabPro-Italic/PFAgoraSlabPro-Italic.woff") format("woff"),
	url("../../fonts/PFAgoraSlabPro-Italic/PFAgoraSlabPro-Italic.ttf") format("truetype"),
	url("../../fonts/PFAgoraSlabPro-Italic/PFAgoraSlabPro-Italic.svg#svgFontName") format('svg');
	font-style: italic;
	font-weight: 400;
}