.management {
    @media screen and (max-width: 991px) {
        padding-bottom: 2em;
    }

    &__list {
        padding-top: 3em;
    }

    &__item {
        text-align: center;
        @media screen and (max-width: 991px) {
            margin-bottom: 3em;
        }
        .img-wrap {
            width: 100%;
            max-width: 190px;
            margin: 0 auto;
            margin-bottom: 30px;
            font-size: 0;
            @include border-radius(50%);
            overflow: hidden;
            @media screen and (max-width: 768px) {
                max-width: 160px;
            }
            > img {
                width: 100%;
                height: auto;
            }
        }
        .name {
            margin-bottom: 1.5em;
            font-weight: 700;
        }
        .position {
            margin-bottom: 1.5em;
            font-family: PFAgoraSlabPro, GothamPro, Arial, Helvetica, Verdana, sans-serif;
            font-style: italic;
            color: $color-text-dark-grey;
        }
        .btn-wrap {
            margin-bottom: 1.5em;
            .btn {
                margin-right: .5em;
            }
        }
        .descr-gen, .descr-sub {
            width: 100%;
            max-width: 430px;
            margin: 0 auto;
        }
        .descr-gen {
            margin-bottom: 1em;
        }
        .descr-sub {
            color: $color-text-dark-grey;
        }
    }

}