.projects-all {

    &__list {
        padding-top: 2em;
    }

    &__item {
        margin-bottom: 6em;
        .logo-wrap {
            width: 100%;
            max-width: 15em;
            height: 3em;
            margin: 0 auto;
            margin-bottom: 1.5em;
            text-align: center;
            > img {
                display: inline-block;
                max-width: 100%;
                max-height: 100%;
                height: auto;
            }
        }
        .img-wrap {
            position: relative;
            width: 100%;
            max-width: 230px;
            height: 160px;
            margin: 0 auto;
            text-align: center;
            margin-bottom: 20px;
            > img {
                width: auto;
                max-width: 100%;
                height: auto;
                max-height: 100%;
            }
        }
        .descr {
            margin-bottom: 1.5em;
            padding: 0 5%;
            text-align: center;
            @media screen and (max-width: 991px) {
                padding: 0;
            }
        }
        .more-link {
            color: $email-color-hover;
            text-align: center;
            > a {
                color: $email-color-hover;
                &:hover {
                    color: $email-color;
                }
            }
        }
    }

    .btn-wrap {
        margin-bottom: 3em;
    }

    &__footer {
        .content {
            p {
                @media screen and (max-width: 768px) {
                    margin-bottom: 1em;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            p > span {
                display: inline-block;
                vertical-align: top;
            }
            .point {
                @media screen and (max-width: 768px) {
                    display: none;
                }
            }
        }
    }

}